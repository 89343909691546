<template>

<section id="form-canal">
  <div class="row mt-3">
    <div class="col-12">
      <h3 class="section-head-title ps-0 mb-3">
        <font-awesome-icon 
          icon="tv" 
          class="color-secondary pe-1"
        />
        <strong>Crear canal:</strong> Ingresa la información de tu canal.
      </h3>

      <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
          <div class="card-body d-flex flex-column mh-700">
            <div class="row flex-grow-1 mt-3">
              <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary">Datos de tu canal</h5>
                <div class="card flex-grow-1">
                  <div class="card-body">

                    <div class="row mb-3">
                      <div class="col-12">
                        <label class="text-secondary">Nombre</label>
                        <input 
                          type="text" 
                          class="form-control input-custom" 
                          placeholder="Ingresa el nombre de tu canal"
                          maxlength="150"
                          v-model="channel_name"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <label class="text-secondary">Descripción</label>
                        <textarea 
                          class="form-control textarea-custom" 
                          placeholder="Ingresa una breve descripción del canal. Esta puede incluir detalles de su ubicación."
                          v-model="channel_description"
                          maxlength="75"
                          rows="3"
                        >
                        </textarea>
                        <div class="form-text text-end">
                          {{ channel_description!= undefined? channel_description.length: "0" }}/75
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5 d-flex flex-column mt-3 mt-lg-0">
                <h5 class="font-main text-secondary">Periodicidad de transmisión</h5>
                <div class="card flex-grow-1">
                  <div class="card-body">
                    <label class="text-secondary">Selecciona los días de transmisión</label>
                    <ul class="list-group">
                      <li class="list-group-item">
                        <input 
                          id="diasTodaLaSemana" 
                          class="form-check-input me-1" 
                          type="radio" 
                          value="1"
                          v-model="channel_days"
                          checked
                        >
                        <label 
                          class="form-check-label stretched-link" 
                          for="diasTodaLaSemana">
                          Toda la semana
                        </label>
                      </li>
                      <li class="list-group-item">
                        <input 
                          id="diasPersonzalizado"
                          class="form-check-input me-1" 
                          type="radio" 
                          value="0"
                          v-model="channel_days"
                        >
                        <label 
                          class="form-check-label stretched-link" 
                          for="diasPersonzalizado">
                          Personalizado
                        </label>
                      </li>
                    </ul>
                    <div 
                      v-if="channel_days === '0'"
                      class="row mt-3">
                      <div class="col-12">
                        <h6 class="font-main text-secondary border-bottom mt-1 pb-2">
                          <font-awesome-icon icon="calendar" class="color-secondary"/>
                          Selecciona los días
                        </h6>
                        <ul class="form-days list-inline">
                          <li class="list-inline-item">
                            <button
                              @click="selectDay(1)"
                              class="form-days-item"
                              :class="getSelectedButton(1) ? 'active' : ''" 
                              :disabled="active_week == false">
                              L
                            </button>
                          </li>
                          <li class="list-inline-item">
                            <button 
                              @click="selectDay(2)"
                              class="form-days-item"
                              :class="getSelectedButton(2) ? 'active' : ''" 
                              :disabled="active_week == false">
                              M
                            </button>
                          </li>
                          <li class="list-inline-item">
                            <button
                              @click="selectDay(3)"
                              class="form-days-item"
                              :class="getSelectedButton(3) ? 'active' : ''" 
                              :disabled="active_week == false">
                              X
                            </button>
                          </li>
                          <li class="list-inline-item">
                            <button
                              @click="selectDay(4)"
                              class="form-days-item"
                              :class="getSelectedButton(4) ? 'active' : ''" 
                              :disabled="active_week == false">
                              J
                            </button>
                          </li>
                          <li class="list-inline-item">
                            <button
                              @click="selectDay(5)"
                              class="form-days-item"
                              :class="getSelectedButton(5) ? 'active' : ''" 
                              :disabled="active_week == false">
                              V
                            </button>
                          </li>
                          <li class="list-inline-item">
                            <button 
                              @click="selectDay(6)"
                              class="form-days-item"
                              :class="getSelectedButton(6) ? 'active' : ''" 
                              :disabled="active_week == false">
                              S
                            </button>
                          </li>
                          <li class="list-inline-item">
                            <button 
                              @click="selectDay(7)"
                              class="form-days-item"
                              :class="getSelectedButton(7) ? 'active' : ''" 
                              :disabled="active_week == false">
                              D
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <label class="text-secondary mt-3">Selecciona el horario de transmisión</label>
                    <ul class="list-group">
                      <li class="list-group-item">
                        <input 
                          id="horario24Horas" 
                          class="form-check-input me-1" 
                          type="radio" 
                          value="1"
                          v-model="channel_hours"
                          checked
                        >
                        <label 
                          class="form-check-label stretched-link" 
                          for="horario24Horas">
                          24 Horas
                        </label>
                      </li>
                      <li class="list-group-item">
                        <input 
                          id="horarioPersonzalizado"
                          class="form-check-input me-1" 
                          type="radio" 
                          value="0"
                          v-model="channel_hours"
                        >
                        <label 
                          class="form-check-label stretched-link" 
                          for="horarioPersonzalizado">
                          Personalizado
                        </label>
                      </li>
                    </ul>

                    <div 
                      v-if="channel_hours === '0'"
                      class="row mt-3">
                      <div class="col-12">
                        <h6 class="font-main text-secondary border-bottom mt-1 pb-2">
                          <font-awesome-icon icon="calendar" class="color-secondary"/>
                          Selecciona los días
                        </h6>

                        <div class="row mt-3">
                          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                            <label class="text-secondary">Horario de inicio</label>

                            <div class="d-flex">
                              <div class="flex-grow-1">
                                <input 
                                  class="form-control input-custom" 
                                  type="number"
                                  min="00"
                                  max="23"
                                  value="00"
                                  :disabled="active_hours == false"
                                  v-model="inicio_hora"
                                >
                              </div>
                              <div class="d-flex justify-content-center align-items-center px-2">
                                :
                              </div>
                              <div class="flex-grow-1">
                                <input 
                                  class="form-control input-custom" 
                                  type="number"
                                  min="00"
                                  max="59"
                                  value="00"
                                  :disabled="active_hours == false"
                                  v-model="inicio_min"
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <label class="text-secondary">Horario de término</label>

                            <div class="d-flex">
                              <div class="flex-grow-1">
                                <input 
                                  class="form-control input-custom" 
                                  type="number"
                                  min="00"
                                  max="23"
                                  value="23"
                                  :disabled="active_hours == false"
                                  v-model="termino_hora"
                                >
                              </div>
                              <div class="d-flex justify-content-center align-items-center px-2">
                                :
                              </div>
                              <div class="flex-grow-1">
                                <input 
                                  class="form-control input-custom" 
                                  type="number"
                                  min="00"
                                  max="59"
                                  value="59"
                                  :disabled="active_hours == false"
                                  v-model="termino_min"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
                  <router-link 
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                    :to="{ name: 'CanalCorporativo-canales' }"
                  >
                    Volver
                  </router-link>
                  <button
                    v-show="create_mode == true"
                    class="btn btn-custom-color-blue border-round-50 mw-100"
                    @click="addChannel()"
                    :disabled="active_button == false"
                  >
                    Añadir
                  </button>
                  <button
                    v-show="create_mode == false"
                    class="btn btn-custom-color-blue border-round-50 mw-100"
                    @click="editChannel()"
                    :disabled="active_button == false"
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
    </div>
  </div>

  <Spinner v-show="show_spinner"/>
  
</section>

</template>
 
 <script>
import { mapActions, mapGetters } from "vuex";
import Spinner from "../Spinner.vue";
import moment from 'moment';

export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      show_spinner: false,
      channel_name: "",
      channel_description: "",
      active_button: false,
      create_mode: true,
      channel_days: "",
      channel_hours: "",
      active_week: false,
      active_hours: false,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario : this.$ls.get("user").id_usuario,
      selected_day: "1",
      inicio_hora: "00",
      inicio_min: "00",
      termino_hora: "00",
      termino_min: "00",
      dias_frecuencias: [],
      days_ok: false
    };
  },
  created() {
    this.channel;
    if(this.$route.params.id!=undefined &&  this.$route.params.id!=null){
      console.log("id para editar: ", this.$route.params.id);
    }
  },
  mounted() {
    if (this.id != undefined) {
      this.create_mode = false;
      this.active_button = true;
      this.channel_name = this.channel.nombre_canal;
      this.channel_description = this.channel.descripcion_canal;
      this.channel_days = this.channel.reproduccion_dia;
      this.channel_hours = this.channel.reproduccion_hora;
      if (this.channel.reproduccion_hora!=1 || this.channel_days.reproduccion_dia!=1) 
      {
        if (this.channel.frecuencia.length>0)
        {   if (this.channel.frecuencia[0].hora_inicio!=null){
              let formatoHoraInicio = this.channel.frecuencia[0].hora_inicio.split(":");
              let formatoHoraTermino = this.channel.frecuencia[0].hora_fin.split(":");
              this.inicio_hora = formatoHoraInicio[0];
              this.inicio_min = formatoHoraInicio[1];
              this.termino_hora = formatoHoraTermino[0];
              this.termino_min = formatoHoraTermino[1];
            }
            if(this.channel_days==0)
              { //parseInt(data.num_dia)
                console.log("dentro de mount para dias frecuencia", this.dias_frecuencias);
                this.channel.frecuencia.forEach(data=> 
                  this.dias_frecuencias.push(parseInt(data.num_dia)) 
                );
              }
        }
        
      }
    }
  },
  watch: {
    channelConfig: {
      handler(newVal, oldVal) {
        if (this.channel_hours == "0") {
          this.active_hours = true;
        } else if (this.channel_hours == "1"){
          this.active_hours = false;
        }

        if (this.channel_days == "0") {
          this.active_week = true;
          if (this.dias_frecuencias.length != 0) {
            this.days_ok = true;
          } else {
            this.days_ok = false;
          }
        } else if (this.channel_days == "1") {
          this.active_week = false;
          this.days_ok = true;
          if (oldVal.channel_days == "0") {
            this.dias_frecuencias = [];
          }
        }

        if (this.channel_name.length != 0 && this.days_ok && this.channel_hours !== "") {
          this.active_button = true;
        } else if (this.channel_name.length == 0 || !this.days_ok || !this.channel_hours) {
          this.active_button = false;
        }
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters("canalCorporativoModule", ["getChannelById"]),
    channel() {
      const { id } = this;
      return {
        ...this.getChannelById(+id),
      };
    },

    channelConfig() {
      const { channel_name, channel_days, channel_hours, dias_frecuencias } = this;
      return { channel_name, channel_days, channel_hours, dias_frecuencias };
    }
  },
  
  methods: {
   ...mapActions("canalCorporativoModule", ["addCanal", "editCanal"]),
    async addChannel() {
      const horaInicioDuration  = moment.duration(`${this.inicio_hora}:${this.inicio_min}`);
      const horaTerminoDuration = moment.duration(`${this.termino_hora}:${this.termino_min}`);
      const horaInicioFormatted  = moment.utc(horaInicioDuration.as('milliseconds')).format('HH:mm:ss');
      const horaTerminoFormatted = moment.utc(horaTerminoDuration.as('milliseconds')).format('HH:mm:ss');
      let data = {
        id_empresa: this.id_empresa,
        frecuencia_diaria: parseInt(this.channel_days),
        frecuencia_horaria: parseInt(this.channel_hours),
        nombre_canal: this.channel_name,
        id_usuario: this.id_usuario,
        descripcion_canal: this.channel_description,
        dias_frecuencia: this.dias_frecuencias,
        hora_inicio: horaInicioFormatted,
        hora_fin: horaTerminoFormatted
      };
      this.show_spinner = true;
      await this.addCanal(data).then(res=>{
        if (!res){
          this.show_spinner = false;
          this.$toast.open({
            message: "Falla al momento de agregar canal",
            type: "error",
            duration: 6000,
            position: "top-right",
          });
        }
        else {
          this.show_spinner = false;
          this.$toast.open({
            message: "Tu canal ha sido agregado correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$router.push({ name: "CanalCorporativo-canales" });
        }
      });
    },
    async editChannel() {
      let horaInicio = "";
      let horaTermino = "";
      if(this.channel_hours == 0){
        horaInicio = this.inicio_hora+":"+this.inicio_min+":00";
        horaTermino = this.termino_hora+":"+this.termino_min+":00";
      }
      if(this.channel_days == 1){
        this.dias_frecuencias = [];
      }
      let data = {
        id_empresa: this.id_empresa,
        frecuencia_diaria: parseInt(this.channel_days),
        frecuencia_horaria: parseInt(this.channel_hours),
        nombre_canal: this.channel_name,
        id_usuario: this.id_usuario,
        descripcion_canal: this.channel_description,
        dias_frecuencia: this.dias_frecuencias,
        hora_inicio: horaInicio,
        hora_fin: horaTermino,
        id_canal: this.id
      
      }
      this.show_spinner = true;
      await this.editCanal(data).then(res=>{
        console.log("ACA REVISANDO EL RESULTADO DE EDITAR", res);
        if (!res){
          this.show_spinner = false;
          this.$toast.open({
            message: "Falla al momento de editar canal, favor contactar al administrador",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        }
        else {
          this.show_spinner = false;
          this.$toast.open({
            message: "Tu canal ha sido editado correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
          this.$router.push({ name: "CanalCorporativo-canales" });
        }
      });
      
    },

    selectDay(day){
      let found_index = this.dias_frecuencias.findIndex(dias => dias == day);
      //console.log("dias_frecuencia :" ,this.dias_frecuencias);
      //console.log("revisando selectDat", found_index);
      if(found_index==-1){
        this.dias_frecuencias.push(day)
      }
      else {
        this.dias_frecuencias.splice(found_index, 1);
      }
      //console.log("selected:" ,this.getSelectedButton(day));
      //console.log("dias_frecuencia despues de accion:" ,this.dias_frecuencias);
    },
    getSelectedButton(day){
      let existe = this.dias_frecuencias.find((dias) => dias == day);
      let status = false;
      if(existe){
        status = true;
      }
      return status;
    }
  },
  components: { Spinner },
};
</script>